<template>
  <section>
    <HeroSection headName="DirectAdmin" :searchBar="true" :addButton="false">
      <div class="searchbar__wrap">
        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Conta DA Tails</th>
                <th class="a-center">Armazenamento</th>
                <th class="a-center"><font-awesome-icon :icon="['fa', 'database']" /></th>
                <th class="a-center">Suspenso</th>
                <th class="a-center">Monitorado em</th>
                <th class="a-center">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(dataList.length - index, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img :src="baseUrl('/img/directadmin.jpg')" :alt="item.company">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ item.user }}
                    </span>

                    <span>
                      {{ item.domain }}
                    </span>

                    <span>
                      {{ item.os_name }} | {{ item.hostname }} | {{ item.creator }}
                    </span>
                  </div>
                </td>
                <td class="a-center">{{ calcSizes(item) }}</td>
                <td class="a-center">
                  <span :class="sizeColor(item.sized)">
                    <font-awesome-icon :icon="['fa', 'database']" />
                  </span>
                </td>
                <td class="a-center">
                  <span class="tsuspension" :class="item.suspended ? 'tsuspension--has' : ''">
                    <font-awesome-icon :icon="['fa', 'triangle-exclamation']" />
                  </span>
                </td>
                <td class="a-center">{{ brDateWithTime(item.monitored_at) }}</td>
                <td class="a-center">
                  <a :href="goToDa(item.user)" target="_blank" class="table__go" title="Ir para DirectAdmin">
                    <font-awesome-icon :icon="['fa', 'right-to-bracket']" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import monitor from '@services/monitor';
import { baseUrl, padVal } from '@helpers/functions';
import moment from 'moment';

export default {
  components: {
    HeroSection,
    NoData,
    DataLoading
  },
  methods: {
    baseUrl,
    padVal,
    initLoad() {
      const _this = this;
      const { search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        search
      };

      _this.loading = true;
      monitor.retrieve('da', params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    brDateWithTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    calcSizes(item) {
      let { usage, quota, sized } = item;

      usage = (usage / 1024).toFixed(2);
      quota = (quota / 1024).toFixed(2);
      sized = Math.round(sized);

      return `${usage}GB / ${quota}GB (${sized}%)`;
    },
    sizeColor(sized) {
      if(sized < 75) return 'color--green';
      if(sized < 90) return 'color--orange';
      if(sized < 100) return 'color--red';
    },
    goToDa(user) {
      return `https://tails.flize.cloud:2087/evo/reseller/users/${user}/view/domains`;
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad(false);
  }
};

</script>
