<template>
  <section>
    <HeroSection headName="Servidores" :searchBar="true" :addButton="false">
      <div class="searchbar__wrap">
        <div class="searchbar__group">
          <label for="" class="searchbar__label">
            Pesquisar:
          </label>

          <input class="searchbar__input searchbar__input--cliente" type="text" v-model="filters.search" @keyup.enter="doSearch()" >
        </div>
      </div>
    </HeroSection>

    <div class="container">

      <div class="table">
        <DataLoading rows="5" height="100px" v-if="loading"/>

        <NoData v-if="!loading && dataList.length == 0"/>

        <div class="table__responsive" v-if="!loading && dataList.length">
          <table>
            <thead>
              <tr>
                <th class="a-center">#</th>
                <th class="a-left">Informações de Servidor</th>
                <th class="a-center">Armazenamento</th>
                <th><font-awesome-icon :icon="['fa', 'database']" /></th>
                <th class="a-center">Processamento</th>
                <th><font-awesome-icon :icon="['fa', 'microchip']" /></th>
                <th class="a-center">Memória RAM</th>
                <th><font-awesome-icon :icon="['fa', 'memory']" /></th>
                <th class="a-center">Memória SWAP</th>
                <th><font-awesome-icon :icon="['fa', 'car-battery']" /></th>
                <th class="a-center">Monitorado em</th>
                <th class="a-center">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in dataList" :key="index">
                <td class="a-center">{{ padVal(dataList.length - index, 4) }}</td>
                <td class="table__box">
                  <div class="table__lbox">
                    <img :src="baseUrl(`/img/${checkHostName('image', item.hostname)}.jpg`)" :alt="item.hostname">
                  </div>

                  <div class="table__rbox">
                    <span>
                      {{ checkHostName('name', item.hostname) }}
                    </span>

                    <span >
                      {{  item.hostname == 'dexter' || item.hostname == 'sonic' || item.hostname == 'shakira' ? 'Servidor Físico' : 'Servidor Virtual'  }}
                    </span>

                    <span>
                      {{ item.os_name }} | {{ checkHostName('name', item.hostname) }}
                    </span>
                  </div>
                </td>
                <td class="a-center">{{ calcSizes(item.disk_total, item.disk_used) }}</td>
                <td class="a-center">
                  <span :class="sizeColor('disk', item)">
                    <font-awesome-icon :icon="['fa', 'database']" />
                  </span>
                </td>
                <td class="a-center">{{ calcCpu(item.cpu_total, item.cpu_used) }}</td>
                <td class="a-center">
                  <span :class="sizeColor('cpu', item)">
                    <font-awesome-icon :icon="['fa', 'microchip']" />
                  </span>
                </td>
                <td class="a-center">{{ calcSizes(item.ram_total, item.ram_used) }}</td>
                <td class="a-center">
                  <span :class="sizeColor('ram', item)">
                    <font-awesome-icon :icon="['fa', 'memory']" />
                  </span>
                </td>
                <td class="a-center">{{ calcSizes(item.swap_total / 1024, item.swap_used / 1024) }}</td>
                <td class="a-center">
                  <span :class="sizeColor('swap', item)">
                    <font-awesome-icon :icon="['fa', 'car-battery']" />
                  </span>
                </td>
                <td class="a-center">{{ brDateWithTime(item.monitored_at) }}</td>
                <td class="a-center">
                  <a :href="goToDash(item.hostname)" target="_blank" class="table__go" title="Ir para Painel">
                    <font-awesome-icon :icon="['fa', 'right-to-bracket']" />
                  </a>
                  <span v-if="item.hostname != 'dexter' && item.hostname != 'sonic' && 1 == 2">
                    -
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import NoData from '@components/_partials/NoData.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import monitor from '@services/monitor';
import { baseUrl, padVal } from '@helpers/functions';
import moment from 'moment';

export default {
  components: {
    HeroSection,
    NoData,
    DataLoading
  },
  methods: {
    baseUrl,
    padVal,
    initLoad() {
      const _this = this;
      const { search } = _this.filters;
      const params = {
        page: _this.$route.query.page ? _this.$route.query.page : null,
        search
      };

      _this.loading = true;
      monitor.retrieve('ts', params).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { docs, ...pagination } = res.data;
          _this.dataList = docs;
          _this.pagination = pagination;
        }
      });
    },
    doSearch() {
      const _this = this;
      const search = _this.filters.search;
      if(search.length > 3) {
        _this.initLoad();
        _this.filters.search = '';
      }
      if(!search.length) {
        _this.initLoad();
      }
    },
    brDateWithTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    checkHostName(type, hostname) {
      let named = {};

      if(!hostname.includes('.') && !hostname.includes('-')) {
        named.name = hostname;
        named.image = 'proxmoxvm';
        if(hostname == 'dexter' || hostname == 'sonic' || hostname == 'shakira') named.image = 'proxmox';
        return named[type];
      }

      let hasSeparator = false;
      if(hostname.includes('.')) hasSeparator = '.';
      if(hostname.includes('-')) hasSeparator = '-';

      if(hasSeparator !== false) {
        const words = ['host', 'info', 'cloud', 'master', 'droplet', 'com', 'br', 'flize'];
        let splited = hostname.split(hasSeparator);

        const filtered = splited.filter(word => !words.includes(word));

        named.name = filtered[0];
        named.image = 'linux';
        if(hasSeparator == '.') named.image = 'hivelocity';
        if(hasSeparator == '-') named.image = 'digitalocean';

        return named[type];
      }

      named.name = hostname;
      named.image = 'linux'
      return named;
    },
    calcSizes(total, used) {
      if(total == 0) return '-';
      total = (total / 1024).toFixed(2);
      used = (used / 1024).toFixed(2);
      let sized = Math.round((used / total) * 100);
      return `${used}GB / ${total}GB (${sized}%)`;
    },
    calcCpu(total, used) {
      let perCore = (used / total).toFixed(2);
      return `${perCore}% / ${total}vCPU`;
    },
    sizeColor(type, item) {
      if(type == 'cpu') {
        let perCore =  item.cpu_used / item.cpu_total;
        if(perCore < 50) return 'color--green';
        if(perCore < 80) return 'color--orange';
        if(perCore < 200) return 'color--red';
      }

      if(type == 'ram') {
        let sized = (item.ram_used / item.ram_total) * 100;
        if(sized < 60) return 'color--green';
        if(sized < 90) return 'color--orange';
        if(sized < 200) return 'color--red';
      }

      if(type == 'swap') {
        let sized =  item.swap_total ? (item.swap_used / item.swap_total) * 100 : 0;
        if(sized < 60) return 'color--green';
        if(sized < 80) return 'color--orange';
        if(sized < 200) return 'color--red';
      }

      if(type == 'disk') {
        let sized =  (item.disk_used / item.disk_total) * 100;
        if(sized < 75) return 'color--green';
        if(sized < 90) return 'color--orange';
        if(sized < 200) return 'color--red';
      }
    },
    goToDash(hostnamed) {
      const hostname = this.checkHostName('name', hostnamed);
      const image = this.checkHostName('image', hostnamed);

      if(hostname == 'dexter' || hostname == 'sonic') return `https://${hostname}.flize.cloud:8006`
      if(image == 'proxmox' && hostname == 'sonic') return `https://sonic.flize.cloud:8006`;
      if(image == 'proxmox' && hostname == 'shakira') return `https://shakira.atenaeditora.com.br:8006`;
      if(image == 'proxmoxvm' && hostname != 'sonic') return `https://dexter.flize.cloud:8006`;
      if(image == 'hivelocity') return `https://my.hivelocity.net/`;
      if(image == 'digitalocean') return `https://cloud.digitalocean.com/login`;
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      pagination: {},
      filters: {}
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    }
  },
  mounted(){
    this.initLoad(false);
  }
};

</script>
