
import api from 'api';
import { processPromise, obj2Prams } from '@helpers/functions';

const agendamento = {};

agendamento.retrieve = (params = {}) => {
  const querystring = obj2Prams(params);

  return new Promise((resolve, reject) => {
    let endpoint = `/api/agendamentos`;
    endpoint = querystring ? `${endpoint}?${querystring}` : endpoint;

    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

agendamento.show = (_id) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/agendamento/${_id}`;
    api.get(endpoint).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

agendamento.save = (_id, data) => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/agendamento`;
    endpoint = _id ? `${endpoint}/${_id}` : endpoint;
    api.post(endpoint, data).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

agendamento.export = () => {
  return new Promise((resolve, reject) => {
    let endpoint = `/api/agendamentos/export`;
    api.patch(endpoint, {},{ responseType: 'blob' }).then((result) => {
      const response = processPromise(true, result)
      resolve(response);
    }).catch((error) => {
      processPromise(false, error)
      reject(error);
    });
  });
}

export default agendamento
