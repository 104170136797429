<template>
  <div class="heroSection">
    <div class="hero">
      <div class="container">
        <div class="hero__wrap">
          <h1 class="hero__name">
            <font-awesome-icon :icon="['fas', 'hashtag']" /> {{ headName }}
          </h1>

          <div class="hero__go">
            <span class="hero__download" @click="handleClick()" v-if="hasDownload && role == 'admin'">
              <font-awesome-icon :icon="['fas', 'download']" />
            </span>

            <router-link class="hero__anchor" :to="goRoute" title="Cadastrar Cliente" v-if="addButton">
              {{ goLabel }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="searchbar" v-if="searchBar">
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import storage from '@/config/storage';

export default {
  name: 'HeroSection',
  props: {
    headName: {
      type: String,
      required: true
    },
    goLabel: {
      type: String,
      required: false
    },
    goRoute: {
      type: String,
      required: false
    },
    addButton: {
      type: Boolean,
      required: true
    },
    searchBar: {
      type: Boolean,
      required: true
    },
    hasDownload: {
      type: Boolean,
      required: false
    }
  },
  emits: ['makeDownload'],
  methods: {
    handleClick() {
      this.$emit('makeDownload');
    },
  },
  data() {
    return {
      role: storage.get('flizecore--user', 'role')
    }
  }
}
</script>

<style scoped>
.mainLogo {
  width: 100%;
  max-width: 30px;
}
.cls-1 {
  fill: #00aeef;
}

.cls-2 {
  fill: #160e44;
}
</style>
