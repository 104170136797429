import config from 'config';

export function baseUrl(path = '', type = 'app') {
  return config[process.env.NODE_ENV][type] + path;
}

export function processPromise(flag, dataRes) {
  if(flag === true && dataRes?.status == 200) {
    return dataRes?.data;
  }

  if(flag === true && dataRes?.response?.status !== 200) {
    return dataRes?.response?.data;
  }

  if(flag === false) {
    console.warn('processPromise Error =>', dataRes);
    alert('Houve um erro interno na API. Verifique o console.');
  }
}

export function obj2Prams(obj) {
  let data;
  data = Object.keys(obj)
  .map(key => {
    if(obj[key]) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    }
  });

  data = data.filter(item => typeof item !== 'undefined');
  return data.join('&');
}

export function cutString(value, maxWords = 4) {
  if (!value) return '';
  const splitted = value.split(' ');
  let words = [];
  for(let i = 0; i < maxWords; i++) {
    if(typeof splitted[i] !== 'undefined') {
      words.push(splitted[i]);
    }
  }
  return words.join(' ');
}

export function padVal(value, pad = 2) {
  if (!value) return '';
  return value.toString().padStart(pad, '0');
}

export function brDate(date) {
  if (!date) return '';

  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
}

export function globalDate(date) {
  if (!date) return '';

  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();

  return `${year}-${month}-${day}`;
}


export function brMoney(value, style = 'currency') {
  if (!value) value = 0;
  return Number(value).toLocaleString('pt-BR', { style, currency: 'BRL', minimumFractionDigits: 2 });
}

export function docMask(value, withPrefix = false) {
  if(!value) return '';

  let digits = value.replace(/\D/g, '');
  let prefix = '';

  if (digits.length === 11) {
    prefix = 'CPF: ';
    digits = digits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  if (value.length === 14) {
    prefix = 'CNPJ: ';
    digits = digits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  if(withPrefix) digits = `${prefix}${digits}`

  return digits;
}


export function phoneMask(value) {
  let digits = value.replace(/\D/g, '');

  if (digits.length == 10) {
    digits = digits.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  if (value.length == 11) {
    digits = digits.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4');
  }

  return digits;
}


export function nfseUrl(nfse_data, prestador = '20020331000109') {
  const { numero, codigo_verificacao, data_emissao: data_autorizada } = nfse_data;
  const data_emissao = new Date(data_autorizada).toISOString().split('T')[0];

  return `https://pontagrossa.iss.elotech.com.br/autenticar-documento-fiscal?cpfCnpjPrestador=${prestador}&numeroNFSe=${numero}&codigoAutenticidade=${codigo_verificacao}&dataEmissao=${data_emissao}`;
}

export function getDateFromId(_id) {
  let { day, month, year } = _id;
  day = day.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  year = year.toString().padStart(4, '0');
  return `${year}-${month}-${day}`;
}

export function csvDownload(blob, filename = 'data.csv') {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function invoiceIsOpen(status) {
  if(status == 'emitida' || status == 'vencendo' || status == 'vencida' || status == 'pendente') {
    return true;
  }
  return false;
}

