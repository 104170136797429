<template>
  <div class="fatura">
    <div class="fcontainer">
      <DataLoading rows="8" height="100px" v-if="loading"/>

      <div class="fshow" v-if="!loading">
        <div class="flogo">
          <img src="https://assets.flize.cloud/arquivos/flize_billing.png" alt="Flize Tecnologia" width="120" height="60" />
        </div>

        <header class="fheader">
          <div class="finfo">
            <h1 class="fname">Fatura Flize</h1>
            <h2 class="fkey">Identificador: #{{ data._cod }}</h2>
          </div>

          <div>
              <span class="fstatus" :class="invoiceStatus(data.invoice_status, 'class')">
                {{ invoiceStatus(data.invoice_status, 'label') }}
              </span>
          </div>
        </header>

        <section>
          <ul class="fcustomer">
            <li>
                <span>Cliente:</span>

                <span>{{ data?.customer?.name }}</span>
            </li>

            <li>
                <span>CNPJ/CPF:</span>

                <span>{{ docMask(data?.customer?.document) }}</span>
            </li>
          </ul>

          <ul class="finfos">
            <li>
                <span>Vencimento:</span>

                <span>{{ brDate(data.maturity_date) }}</span>
            </li>

            <li>
                <span>Valor:</span>

                <span>{{ brMoney(data.invoice_value) }}</span>
            </li>

            <li>
                <span>Periodicidade:</span>


                <span>{{ data.schedule_id ? schedulePeriod(data.periodicidy, 'label') : 'Não se aplica' }}</span>
            </li>

            <li>
              <span>Tipo:</span>

              <span>{{ typeServices(data.service_type, 'label') }}</span>
            </li>
          </ul>

          <ul class="fdescription">
            <li>
                <span>Descritivo da Fatura:</span>

                <span v-html="data.full_desc "></span>
            </li>
          </ul>

          <div class="freseller" v-if="data.service_type == 'revenda_cloud'">
            <font-awesome-icon :icon="['fa', 'users']" />

            <span><strong>Caro Revendedor:</strong> Verifique suas contas e cotas acessando a opção abaixo em "Planilha de Contas"</span>
          </div>


          <div class="fterms">
            <label class="fcheckwrap">Li e concordo com os termos da Flize Tecnologia conforme descrito <a :href="data.terms" title="Termos de Uso" target="_blank">neste documento</a>.
                <input type="checkbox" :checked="acepted" @change="onAcept"/>
                <span class="fcheckmark" ></span>
            </label>
          </div>

          <div class="ffastpay" :class="enableButtons === true ? '' : 'fdisabled'">
            <h3 class="ffastpay__header">
              <font-awesome-icon :icon="['fa', 'person-snowboarding']" />

              Pagamento Rápido
            </h3>

            <ul class="ffastpay__payer">
              <li>
                <span>Pagador:</span>
                <span>
                  {{ docMask(data?.customer?.document) }}
                  |
                  {{ data?.customer?.name }}
                </span>
              </li>

              <li>
                <span>Vencimento:</span>
                <span>
                  {{ brDate(data.maturity_date) }}
                </span>
              </li>

              <li>
                <span>Valor:</span>
                <span>
                  {{ brMoney(data.invoice_value) }}
                </span>
              </li>
            </ul>

            <div class="ffastpay__wrap">
              <div class="ffastpay__boleto" v-if="enableButtons && invoiceIsOpen(data.invoice_status)">
                <div class="ffastpay__barcode">
                  <div class="ffastpay__info">
                    <p>Realize seu pagamento de forma rápida utilizando a câmera de seu dispositivo para leitura do código ou copiando os dados através dos botões abaixo.</p>

                    <p>Para mais informações sobre dados bancários, juros ou mora, acesse o boleto em "Ver Boleto".</p>
                  </div>

                  <div class="ffastpay__barcode2">
                    <span>{{ bank_slip.digitable_line }}</span>

                    <img :src="bank_slip.barcode" :alt="bank_slip.barcode_data" />
                  </div>
                </div>
              </div>

              <div class="ffastpay__pix" v-if="enableButtons && invoiceIsOpen(data.invoice_status)">
                <img :src="pix.qrcode" :alt="pix.qrcode_text" />
              </div>
            </div>

            <ul class="fbuttons" v-if="payment_url">
              <li class="go--js" :class="enableButtons === true ? '' : 'fdisabled2'" v-if="invoiceIsOpen(data.invoice_status)">
                <a href="#" title="Ver Boleto" ref="copyPix" onclick="return false;">
                    <font-awesome-icon :icon="['fab', 'pix']" />

                    <h3>{{ pixLabel }}</h3>
                </a>
              </li>

              <li class="go--js" :class="enableButtons === true ? '' : 'fdisabled2'">
                <a :href="payment_url" title="Ver Boleto" target="_blank">
                    <font-awesome-icon :icon="['fa', 'barcode']" />

                    <h3>Ver Boleto</h3>
                </a>
              </li>

              <li class="go--js"  :class="enableButtons === true ? '' : 'fdisabled2'" v-if="data.has_nfse && data.nfse_data && data.nfse_data.nfse_status == 'autorizado'">
                <a :href="nfseUrl(data.nfse_data)" title="Ver Nota Fiscal" target="_blank">
                    <font-awesome-icon :icon="['fa', 'file-invoice']" />

                    <h3>Ver Nota Fiscal</h3>
                </a>
              </li>

              <li class="go--js"  :class="enableButtons === true ? '' : 'fdisabled2'" v-if="data.service_type == 'revenda_cloud'">
                <a :href="`${baseUrl('', 'api')}/api/cliente/planilha/${data.token}`" title="Fazer Download" target="_blank">
                  <font-awesome-icon :icon="['fa', 'users']" />

                  <h3>Planilha de Contas</h3>
                </a>
              </li>
            </ul>
          </div>


        </section>

        <footer class="ffooter">
          <h4>
            <font-awesome-icon :icon="['fa', 'fa-triangle-exclamation']" />
              FIQUE ATENTO!
          </h4>

          <ul>
              <li>Apenas é enviado e-mails de cobrança sendo o remetente <a href="mailto:noreply@flize.com.br" title="E-mail Flize" target="_blank">noreply@flize.com.br</a> ou <a href="mailto:financeiro@flize.com.br" title="E-mail Flize" target="_blank">financeiro@flize.com.br</a>. Caso receba alguma cobrança de outros e-mails desconsiderar totalmente.</li>
              <li>Para mais informações jurídicas acesse nossos termos <a :href="data.terms" title="Termos de Uso" target="_blank">clicando aqui</a>.</li>
          </ul>
        </footer>

        <div class="fdev">
          <a href="https://flize.com.br" title="Flize Tecnologia" target="_blank">
              Flize Tecnologia | 2011 - {{ year }}
          </a>
        </div>

        <div class="fshare">
          <a :href="`https://api.whatsapp.com/send?text=${baseUrl()}/cliente/fatura/${data.token}`" title="Enviar via Whatsapp" target="_blank">
            <font-awesome-icon :icon="['fa', 'up-right-from-square']" />
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Clipboard from 'clipboard';
import DataLoading from '@components/_partials/DataLoading.vue';
import { baseUrl, brMoney, brDate, docMask, nfseUrl, invoiceIsOpen } from '@helpers/functions';
import { invoiceStatus, schedulePeriod, typeServices } from '@helpers/constants'
import fatura from '@services/fatura';

export default {
  components: {
    DataLoading
  },
  data() {
    return {
      loading: false,
      data: {},
      acepted: false,
      enableButtons: false,
      payment_url: '',
      iugu_data: {},
      bank_slip: {},
      pix: {},
      pixLabel: 'Copiar PIX',
      year: new Date().getFullYear(),
      clipboardInstance: null
    }
  },
  methods: {
    invoiceStatus,
    schedulePeriod,
    typeServices,
    baseUrl,
    brMoney,
    brDate,
    docMask,
    nfseUrl,
    invoiceIsOpen,
    initLoad() {
      const _this = this;
      const _id =  _this.$route.params.id ? _this.$route.params.id : null;
      const no =  _this.$route.params.noview ? _this.$route.params.noview : null;

      _this.loading = true;
      fatura.public(_id, no).then((res) => {
        _this.loading = false;

        if(typeof res !== 'undefined' && res.success) {
          const { data } = res;

          if(data.has_gn_legacy === true) {
            _this.payment_url = data.gn_legacy.link;
          }
          if(data.has_gn_legacy === false && data.iugu_data && data._cod <= 14000) {
            _this.payment_url = data.iugu_data.checkout_url;
          }
          if(data.has_gn_legacy === false && data.iugu_data && data._cod > 14000) {
            _this.payment_url = data.iugu_data.bank_slip.bank_slip_url;
          }
          if(data.has_gn_legacy === false && data.iugu_data && data._cod > 14000) {
            _this.iugu_data = data.iugu_data;
            if(data.iugu_data.bank_slip && data.iugu_data.bank_slip.barcode_data) {
              _this.bank_slip = data.iugu_data.bank_slip;
            }
            if(data.iugu_data.pix && data.iugu_data.pix.qrcode_text) {
              _this.pix = data.iugu_data.pix;
            }
          }

          _this.data = data;
          document.title = `Fatura #${data._cod} | Flize Tecnologia`;
        }
      });
    },
    onAcept(event) {
      this.enableButtons = event.target.checked;
    },
    initializeClipboard() {
      const _this = this;
      let pixLabel = _this.pixLabel;

      if (_this.clipboardInstance) {
        this.clipboardInstance.destroy();
      }

      _this.clipboardInstance = new Clipboard(_this.$refs.copyPix, {
        text: () => _this.pix.qrcode_text,
      });

      _this.clipboardInstance.on("success", () => {
        _this.pixLabel = 'PIX Copiado! =D';
        setTimeout(() => { _this.pixLabel = pixLabel }, 3000);
      });

      _this.clipboardInstance.on("error", () => {
        alert("Erro ao copiar PIX!");
      });
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'enableButtons'(flag) {
      if (flag && this.$refs.copyPix) {
        this.initializeClipboard();
      }
    }
  },
  mounted(){
    this.initLoad();
  },
  beforeUnmount() {
    if (this.clipboardInstance) {
      this.clipboardInstance.destroy();
    }
  },
};

</script>
