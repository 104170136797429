import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueMask from '@devindex/vue-mask';
import { FontAwesomeIcon } from './icons';
import './styles/_main.styl';

const app = createApp(App);

app.use(VueMask);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

window.addEventListener('load', () => {
  const loader = document.querySelector('.loader');
  if (loader) {
    loader.remove();
  }
});
