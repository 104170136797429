
import { createRouter, createWebHistory } from 'vue-router';
import storage from './config/storage';

import DashboardLayout from '@/components/_layout/DashboardLayout.vue';
import Login from '@/pages/Login/LoginForm.vue';

import DashboardInfo from '@/pages/Dashboard/DashboardInfo.vue';

import FaturaList from '@/pages/Fatura/FaturaList.vue';
import FaturaForm from '@/pages/Fatura/FaturaForm.vue';
import FaturaPublica from '@/pages/Fatura/FaturaPublica.vue';

import AgendamentoList from '@/pages/Agendamento/AgendamentoList.vue';
import AgendamentoForm from '@/pages/Agendamento/AgendamentoForm.vue';

import ClienteList from '@/pages/Cliente/ClienteList.vue';
import ClienteForm from '@/pages/Cliente/ClienteForm.vue';

import UsuarioList from '@/pages/Usuario/UsuarioList.vue';
import UsuarioForm from '@/pages/Usuario/UsuarioForm.vue';

import MonitorDa from '@/pages/Monitor/DirectAdmin.vue';
import MonitorSr from '@/pages/Monitor/TheServers.vue';

import BancoList from '@/pages/Banco/BancoList.vue';

import CronList from '@/pages/Cronlog/CronlogList.vue';

import DoLogout from '@/pages/Login/DoLogout.vue';

const pageTitle = document.title;

// dashboard routes
const dashRoutes = [
  { name: 'Dashboard', path: '/dashboard', component: DashboardInfo },

  { name: 'Faturas', path: '/faturas', component: FaturaList },
  { name: 'Cadastro de Faturas', path: '/faturas/f/:id?', component: FaturaForm },

  { name: 'Agendamentos', path: '/agendamentos', component: AgendamentoList },
  { name: 'Cadastro de Agendamentos', path: '/agendamentos/f/:id?', component: AgendamentoForm },

  { name: 'Clientes', path: '/clientes', component: ClienteList },
  { name: 'Cadastro do Cliente', path: '/clientes/f/:id?', component: ClienteForm },

  { name: 'Usuários', path: '/usuarios', component: UsuarioList },
  { name: 'Cadastro do Usuário', path: '/usuarios/f/:id?', component: UsuarioForm },

  { name: 'Contas DA', path: '/monitor/directadmin', component: MonitorDa },
  { name: 'Servidores', path: '/monitor/servidores', component: MonitorSr },

  { name: 'Bancos', path: '/bancos', component: BancoList },

  { name: 'Cronlogs', path: '/cronlogs', component: CronList }
];

let children = [];
dashRoutes.forEach((item) => {
  children.push({
    path: item.path,
    name: item.name,
    meta: { title: item.name },
    component: item.component
  })
})

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },
  {
    path: '/',
    component: DashboardLayout,
    children
  },
  {
    path: '/cliente/fatura/:id/:noview?',
    name: 'Fatura',
    component: FaturaPublica,
    meta: { title: 'Fatura' }
  },
  {
    path: '/sair',
    name: 'Sair',
    component: DoLogout,
    meta: { title: 'Sair' }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: -500, behavior: 'smooth' }; // Rolar para o topo com rolagem suave
    }
  }
});

// Navegar para login se não estiver autenticado
router.beforeEach((to, from, next) => {

  document.title = `${to.meta.title} - ${pageTitle}`;

  const local = 'flizecore--user';

  if(storage.get(local) && storage.get(local, 'access_token')) {
    console.log('YES! Está logado. =D');

    if(to.path == '/login') next('/dashboard');
  } else {
    console.log('NOP! Não está Logado. :(');

    if(to.path !== '/login' && to.meta.title !== 'Fatura') next('/login');
  }

  next();
});

export default router;
